<template>
  <div class="clients-container">
     <modal-vue @onChange="onChangeModal" :modal="modal">
      <div class="user-delete-modal">
        <div class="user-delete-inside">
          <p class="modal-header">Delete Topic</p>
          <div class="modal-data">
            <p v-if="showWarning">
            This product will impact on few subsriptions.
          </p>
          <p>
            Are you sure you want to delete this topic (<b>{{deleteTopicObject.topic_name}}</b>) ?
          </p>
          <div class="actions">
            <b-button @click="onChangeModal(false)">Cancel</b-button>
            <b-button @click="removeTopic">Delete</b-button>
          </div>
          </div>
        </div>
      </div>
    </modal-vue>
    <admin-header-vue alias="Topic" v-if="showTopic" @onCreate="onCreate" pageName="" />
    <div class="user-table">
      <table-vue
        :labels="labels"
        :dateFormat="dateFormat"
        :data="topicList"
        :sortObj="sort"
        :canDelete="true"
        :totalCount="totalCount"
        :currentPage="currentPage"
        :limit="limit"
        :sortValidation="sortValidation"
        :titleData="'Topics'"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="showEdit || showDelete"
        @searchText="searchText"
        @onUpdate="onUpdate"
        @onClick="onClick"
        @onChangeSort="onChangeSort"
        @onDelete="onDelete"
        @updatePage="updatePage"

        @updateLimit="updateLimit">
        <div class="column is-3" v-if="moduleOptions && moduleOptions.length">
            <search-drop-down
              label=""
              :showLabel="true"
              :list="moduleOptions"
              :selected="selectedModule"
              @onChange="onChangeModule" />
          </div>
        <dropdown-wrap-vue
        v-if="selectedModule.name === 'voc'"
          @onClickOutside="onClickOutside"
          class="column is-6 form-group">
          <div class="relative">
            <b-input
              type="search"
              ref="categories"
              @focus="onFocus"
              v-model="searchCategory"
              :placeholder="'Select Categories'"
              :icon-right="openCategories ? 'menu-up' : 'menu-down'"
              class="form-control" />
            <common-selection-vue
              :searchText="searchCategory"
              name="category_name"
              @focus="onFocus"
              :open="openCategories"
              :selected="selectedCategories"
              @onChange="onChangeCategoryId"
              :ref="'commonSelection'"
              :list="localSelectedCategories" />
          </div>
        </dropdown-wrap-vue>
          <div class="column is-6 relative" v-if="selectedModule.name === 'voe'">
          <treeselect
            :multiple="true"
            :options="industryOptions"
            :flat="true"
            :sort-value-by="sortValueBy"
            :default-expand-level="1"
            placeholder="Select Industries"
            v-model="selectedIndustries">
            <!-- <div slot="value-label"></div> -->
          </treeselect>
          </div>
        </table-vue>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '../../components/table.vue'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import { metaTitle } from '../../../../constant/data'
import ModalVue from '../../../Admin/components/Modal.vue'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import CommonSelectionVue from '../../../../components/Dropdowns/CommonSelection.vue'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import SearchDropDown from '../../components/SearchDropDown.vue'

export default {
  name: 'Topics',
  metaInfo () {
    return {
      title: metaTitle.topics
    }
  },
  components: {
    tableVue,
    AdminHeaderVue,
    ModalVue,
    DropdownWrapVue,
    CommonSelectionVue,
    Treeselect,
    SearchDropDown
  },
  data () {
    return {
      dateFormat: ['created_at', 'updated_at'],
      labels: [{ name: 'Topic Id', alias: 'topic_id' }, { name: 'Topic Name', alias: 'topic_name' }, { name: 'Is Standard?', alias: 'is_standard' }, { name: 'Parent Id', alias: 'parent_id' }, { name: 'Help Text', alias: 'help_text' }, { name: 'Category Name', alias: 'category_name' }, { name: 'Created At', alias: 'created_at' }, { name: 'Updated At', alias: 'updated_at' }],
      sortValidation: ['topic_id', 'topic_name', 'is_standard', 'help_text', 'parent_id', 'category_name', 'created_at', 'updated_at'],
      search: '',
      sort: { by: 'asc', field: 'topic_id' },
      categoryFilterNeeded: true,
      blankOptionRequired: true,
      modal: false,
      deleteTopicObject: {},
      showWarning: false,
      localSelectedCategories: [],
      selectedCategories: [],
      openCategories: false,
      searchCategory: null,
      selectedModule: {
        id: null,
        name: null
      },
      sortValueBy: 'ORDER_SELECTED',
      isSelectedModuleValid: true,
      selectedIndustries: [],
      industryOptions: []
    }
  },
  computed: {
    ...mapState('topics', ['topicList', 'totalCount']),
    ...mapState('categories', ['categoryList']),
    ...mapState('modules', ['moduleList']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('user', ['userDetails']),
    ...mapState('industries', ['allIndustryList', 'allIndustryIds']),
    moduleOptions () {
      if (this.moduleList && this.moduleList.length) {
        return this.moduleList.filter(m => m.module_name === 'voc' || m.module_name === 'voe').map(({ module_id: id, module_name: name }) => ({ id, name }))
      } else {
        return []
      }
    },
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    showTopic () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.topic.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.topic.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.topic.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.topic.currentPage = newPage
      }
    }
  },
  watch: {
    moduleOptions () {
      this.selectedModule = this.moduleOptions.find(m => m.name === localStorage.getItem('TOPICS_MODULE')) || this.moduleOptions.find(m => m.name === 'voc')
    },
    selectedModule () {
      this.currentPage = 1
      if (this.selectedModule) {
        this.resetTopics()
        this.getData()
      }
      if (this.selectedModule.name === 'voe') {
        const label = this.labels.find(l => l.alias === 'category_name')
        if (label) {
          label.name = 'Industry Name'
          label.alias = 'industry_name'
        }
        const catSortValidIndex = this.sortValidation.indexOf('category_name')
        if (catSortValidIndex !== -1) {
          this.sortValidation[catSortValidIndex] = 'industry_name'
        }
      }
      if (this.selectedModule.name === 'voc') {
        const label = this.labels.find(l => l.alias === 'industry_name')
        if (label) {
          label.name = 'Category Name'
          label.alias = 'category_name'
        }
        const indSortValidIndex = this.sortValidation.indexOf('industry_name')
        if (indSortValidIndex !== -1) {
          this.sortValidation[indSortValidIndex] = 'category_name'
        }
      }
    },
    allIndustryList () {
      // if (this.allIndustryList && this.allIndustryList.length && this.selectedModule.name === 'voe') {
      // this.getData()
      // }
      this.industryOptions = JSON.parse(JSON.stringify(this.allIndustryList))
      // this.localSelectedCategories = allCategories
      this.industryOptions.push({ id: 0, label: '(Blank)' })
      this.selectedIndustries = this.allIndustryIds
      this.selectedIndustries.push(0)
    },
    selectedIndustries () {
      if (!this.selectedIndustries.length) {
        this.resetTopics()
      }
      if (this.selectedModule.name === 'voe') {
        this.getData()
      }
    },
    categoryList () {
      const allCategories = JSON.parse(JSON.stringify(this.categoryList))
      allCategories.push({ category_id: null, category_name: '(Blank)' })
      this.localSelectedCategories = allCategories
      this.selectedCategories = this.localSelectedCategories
    },
    selectedCategories () {
      if (!this.selectedCategories.length) {
        this.resetTopics()
      }
      if (this.selectedModule.name === 'voc') {
        this.getData()
      }
    }
  },
  mounted () {
    this.getModuleList()
    this.getCategoryList()
    this.getHeirarchicalIndustryList()
  },
  methods: {
    ...mapActions('topics', ['getTopicList', 'resetTopics', 'deleteTopic', 'deleteVoeTopic']),
    ...mapActions('categories', ['getCategoryList']),
    ...mapActions('industries', ['getHeirarchicalIndustryList', 'getIndustryList']),
    ...mapActions('modules', ['getModuleList']),
    onChangeModule (module, isInValid) {
      this.selectedModule = module
      this.isSelectedModuleValid = isInValid
      localStorage.setItem('TOPICS_MODULE', module.name)
    },
    /** This method closes the dropdown if a click is detected anywhere outside the boundary of the dropdown menu
     * @public
     */
    onClickOutside () {
      this.openCategories = false
    },
    /** This method opens the category dropdown if a click is detected on the input
     * @public
     */
    onFocus () {
      this.$refs.categories.$el.firstChild.focus()
      this.openCategories = true
    },
    onClick (data) {
      //   this.$router.push(`client/${data.client_id}`)
    },
    /** This method redirects the user to the *Update Topic* page
     * @public
     */
    onUpdate (data) {
      this.$router.push({ path: `topic/update/${data.topic_id}`, query: { module: this.selectedModule.name } })
    },
    /** This method redirects the user to the *Add/Create Topic* page
     * @public
     */
    onCreate () {
      this.$router.push('topic/add')
    },
    /** This method updates the list of selected categories. Only the topics of selected categories will be shown in the table
     * @public
     */
    onChangeCategoryId (categories) {
      this.selectedCategories = categories
    },
    /** This method selects the topic to be deleted, and triggers the opening of a confirmation modal.
     * @param data {Object} - The topic to be deleted
     * @public
     */
    async onDelete (data) {
      this.deleteTopicObject = data
      this.modal = true
    },
    /** This method toggles the visibility of the modal
     * @public
     */
    onChangeModal (value) {
      this.modal = value
    },
    /** This method deletes the topic from the database and refreshes the table
     * @public
     */
    async removeTopic () {
      let res = null
      if (this.selectedModule.name === 'voc') {
        res = await this.deleteTopic(this.deleteTopicObject.topic_id)
      } else if (this.selectedModule.name === 'voe') {
        res = await this.deleteVoeTopic(this.deleteTopicObject.topic_id)
      }
      if (res === 200) {
        this.modal = false
        this.getData()
      }
    },
    /** This method updates the sorting criteria of the table
     * @public
     */
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    /** This method updates the table page that is being viewed
     * @public
     */
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    /** This method updates the number of rows that are being shown by the table
     * @public
     */
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    /** This method updates the search keyword and triggers the *getData()* method
     * @param text {String} - The new search keyword
     */
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    /** This method fetches the topic related data from the database, using the limit/search/sort parameters specified */
    getData () {
      // this.getTopicList({ limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search, categoryList: this.selectedCategories.map(e => e.category_id), module: this.selectedModule })
      if (this.selectedModule.name === 'voc' && this.selectedCategories.length) {
        this.getTopicList({ limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search, categoryList: this.selectedCategories.map(e => e.category_id), module: this.selectedModule })
      }
      if (this.selectedModule.name === 'voe' && this.selectedIndustries.length) {
        this.getTopicList({ limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search, industryList: this.selectedIndustries, module: this.selectedModule })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.clients-container {
  margin: 20px 0px;
  border-radius: 4px;
  background: var(--primary);
   .relative {
      position: relative;
    }
  .user-delete-modal {
    display: flex;
    justify-content: center;
    .user-delete-inside {
      display: flex;
      color: var(--secondary-text-color);
      font-family: Quicksand;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      border-radius: 4px;
      width: 400px;
      height: 200px;
      padding: 25px;
      .modal-data {
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    p {
      font-size: 14px;
    }
      }
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }

    }
    .actions {
      padding-top: 20px;
      text-align: end;
      .button {
        height: 32px;
        border: none;
        font-size: 14px;
        width: 75px;
      }
      .button:nth-child(1) {
        margin-left: 5px;
        background-color: transparent;
        color: var(--secondary-text-color);
        border: 1px solid var(--primary-border-color);
        &:hover {
          border: 2px solid var(--primary-border-color);
          transition: border 0.3s ease-out;
        }
        &:active {
          background-color: #7F8FA466;
          transition: background-color 0.3s ease-out;
        }
      }
      .button:nth-child(2) {
        margin-left: 5px;
        background-color: #FF0000;
        color: white;
        &:hover {
          background-color: #F64A4A;
          transition: background-color 0.3s ease-out;
        }
        &:active {
          background-color: #EE1111;
          transition: background-color 0.3s ease-out;
        }
      }
    }
  }
}

::v-deep .vue-treeselect {
          .vue-treeselect__placeholder {
              font-family: roboto;
              font-size: 14px;
          }
          .vue-treeselect__control {
            background: transparent;
            border: none;
            border: var(--tertiary-border-color) !important;
            border-radius: 4px !important;
            color: var(--user-dropdown-text-color) !important;
              input {
                color: var(--user-dropdown-text-color) !important;
              }
              .vue-treeselect__value-container {
                  content: 'Industry';
                  &:before {
                    content: " Select Industries";
                    color: var(--user-dropdown-text-color) !important;
                    font-family: roboto;
                    font-size: 14px;
                  }
                .vue-treeselect__single-value {
                  color: var(--user-dropdown-text-color) !important;
                  font-family: roboto;
                  font-size: 14px;
                }
                .vue-treeselect__multi-value {
                  display: none !important;
                }
              }
          }
            .vue-treeselect__menu {
              background: var(--dropdown-backgroud) !important;
              color: var(--secondary-text-color) !important;
              border: none;
              border: 1px solid lightgray;

              .vue-treeselect__option--highlight{
                background-color: var(--dropdown-background-hover) !important;
              }
            }
        }
        ::v-deep.vue-treeselect--single .vue-treeselect__option--selected {
          background: var(--dropdown-background-hover)
        }
        ::v-deep.vue-treeselect__multi-value {
          display: none !important;
        }

</style>
